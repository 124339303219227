import React, { useEffect, useState } from "react";
import './../css/savepost.modules.css';

const SavePost = () => {
    const [isError, setIsError] = useState(false);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [url, setUrl] = useState("");
    const [author, setAuthor] = useState("");
    const [error, setError] = useState("");
    const [successMsg, setSuccess] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    const resetForm = () => {
        setAuthor("");
        setTitle("");
        setContent("");
        setUrl("");
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSuccess(false);
        if (title.trim().length === 0) {
            setError("Post title is required");
            setIsError(true);
        }
        else if (content.trim().length === 0) {
            setError("Post Content is required");
            setIsError(true);
        }
        else if ((author.trim().length === 0)) {
            setError("Author name is required");
            setIsError(true);
        }
        else if (url.trim().length !== 0 && (!url.startsWith("http://") && !url.startsWith("https://"))) {
            setError("Enter appropriate URL it must start with http:// or https://");
            setIsError(true);
        }
        else {
            setIsError(false);
            fetch("https://assignment-worker1.pypalkar23.workers.dev/posts", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "username": author,
                    "content": content,
                    "url": url.trim().length !== 0 ? url : undefined,
                    "title": title
                })
            }).then(async (response) => {
                const apiResp = await response.text();
                if (apiResp == "success") {
                    setIsSuccess(true);
                    setSuccess("Your Post has been created.")
                    resetForm();
                }
                else {
                    setIsSuccess(false);
                    setIsError(true);
                    setError(apiResp);
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    return (
        <div className="container">
            <div className="row">
                <div className="posts col-lg-7 col-md-9 col-sm-12">
                    <h1> Add New Post Here</h1>
                    <form onSubmit={handleSubmit}>
                        <div className={`form-group ${isSuccess ? "success-active" : "success-inactive"}`}>
                            <label for="success">{successMsg}</label>
                        </div>
                        <div className={`form-group ${isError ? "active" : "inactive"}`}>
                            <label for="error">{error}</label>
                        </div>
                        <div className="form-group">
                            <label for="title">Title:</label>
                            <input type="title" className="form-control" placeholder="Something like Cloudflare KV" value={title} onChange={e => setTitle(e.target.value)} id="email" />
                        </div>
                        <div className="form-group">
                            <label for="content">Content:</label>
                            <textarea className="form-control" value={content} placeholder="Something like How awesome Cloudflare KV and more.." onChange={e => setContent(e.target.value)} id="content"></textarea>
                        </div>
                        <div className="form-group">
                            <label for="pwd">URL(optional):</label>
                            <input type="text" className="form-control" placeholder="Similar to https://abc.com" value={url} onChange={e => setUrl(e.target.value)} id="content" />
                        </div>
                        <div className="form-group">
                            <label for="author">Author:</label>
                            <input type="text" className="form-control" placeholder="Your name or nickname will work" value={author} onChange={e => setAuthor(e.target.value)} id="author" />
                        </div>
                        <button type="submit" className="btn btn-default">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SavePost;