import React, { useEffect, useState } from "react";
import styles from './../css/posts.modules.css';

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(
        "https://assignment-worker1.pypalkar23.workers.dev/posts",

      ).then(async (resp) => {
        const postsResp = await resp.json();
        console.log(postsResp);
        if (postsResp !== undefined && postsResp !== null) {
          if (Array.isArray(postsResp)) {
            setPosts(postsResp.sort((a, b) => b.date - a.date));
          }
        }
      })
        .catch((err) => {
          console.log(err);
        });
      console.log(resp);
    };
    getPosts();
  }, []);

  return (
    <div className="container">
      {posts.map((post) => (
        <div className={`row`}>
          <div className={`posts col-md-10 col-lg-8 col-sm-12  ${post.url ? "tint" : "non-tint"}`} key={post.id}>
            <div className="title">{post.title}</div>
            <div className="author">By <span class="author-name">{post.username}</span></div>
            <div class="date">
              Published on: <span class="formatted-date">{(new Date(post.date)).toLocaleDateString('en-US', DATE_OPTIONS)}</span>
            </div>
            {!post.url && <div className="content"><p className="content-text">{post.content}</p></div>}
            {post.url && <div className="content-with-url"><p className="context-text-inline">{post.content + " : "}</p><a href={post.url}>{post.url}</a></div>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Posts;