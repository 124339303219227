import { Router, Link } from "@reach/router";
import Posts from './components/posts';
import SavePost from "./components/savepost";
import './App.css';


function App() {
  return (
    <div className="body-wrapper">
      <div className="container-fluid">
        <nav className="navbar navbar-default">
          <div className="container">
            <div className="navbar-header">
              <Link to="/" className="navbar-brand">Cloudflare Posts Assignment</Link>
            </div>
            <ul className="nav navbar-nav">
              <li><Link to="savepost">Add a new Post</Link></li>
            </ul>
          </div>
        </nav>
        <Router>
          <Posts path="/" />
          <SavePost path="/savepost" />
        </Router>
      </div>
    </div>
  );
}

export default App;